<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.98828 4.41599H4.28314M15.9883 4.41599H13.7944M11.6006 4.41599H6.57799M11.6006 4.41599V1.50049H6.57799V4.41599M11.6006 4.41599H13.7944M6.57799 4.41599H4.28314M4.28314 4.41599V15.5005H13.7944V4.41599M7.4151 7.216V12.2098M10.446 7.216V12.2098"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
